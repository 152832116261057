<template>
  <div id="casebox">
    <Header />
    <div class="caseinfo_banner">
        <div class="img">
            <!-- pc -->
            <img :src="caseinfo.banner_pc" alt="">
            <!-- 移动 -->
            <img :src="caseinfo.banner_mb" alt="">
        </div>
        <div class="cont">
            <div class="basecont">
                <div class="tit">{{caseinfo.title}}</div>
            </div>
        </div>
    </div>
    <div class="caseinfo-sec1">
        <!-- <div class="cont" v-for="(item,index) in caseinfo.content" :key="index">
            <div class="basecont">
                <div class="caseinfo-tit base100">
                    <div class="tit basefont30" v-if="item.title">{{item.subtitle}}</div>
                    <div class="text" v-html="item.text"></div>
                </div>
            </div>
            <div class="img">
                <img :src="item.url" alt="">
            </div>
        </div> -->
        <div class="cont">
            <div class="basecont">
                <div class="caseinfo-tit base100">
                    <div class="tit basefont30" v-html="caseinfo.subtitle"></div>
                    <div class="text" v-html="caseinfo.details"></div>
                </div>
            </div>
            <div class="img">
                <img :src="caseinfo.image1" alt="">
            </div>
        </div>
        <div class="cont">
            <div class="basecont">
                <div class="caseinfo-tit base100">
                    <div class="text" v-html="caseinfo.details2"></div>
                </div>
            </div>
            <div class="img">
                <img :src="caseinfo.image2" alt="">
            </div>
        </div>
        <div class="cont">
            <div class="basecont">
                <div class="caseinfo-tit base100">
                    <div class="text" v-html="caseinfo.details3"></div>
                </div>
            </div>
            <div class="img">
                <img :src="caseinfo.image3" alt="">
            </div>
        </div>
        <div class="qie base100">
            <div class="basecont">
                <a href="javascript:;" @click="prevPage" class="hm-but prev">
                    <i>
                        <img src="../../assets/images/prevjiantouimgf1-1.png" >
                        <img src="../../assets/images/prevjiantouimgf1.png" >
                    </i>
                    <span>上一篇</span>
                </a>
                <a href="javascript:;" @click="nextPage" class="hm-but next">
                    <span>下一篇</span>
                    <i>
                        <img src="../../assets/images/jiantouyo1.png" >
                        <img src="../../assets/images/jiantouyo2.png" >
                    </i>
                </a>
            </div>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import merge from 'webpack-merge';
export default {
  name: "Caseinfo",
  components: {
    Header,
    Footer
  },
  data(){
    return {
        id: "",
        caseinfo:[]
    }
  },
  beforeCreate(){
    
  },
  created:function (){
      
  },
  mounted:function(){
    // this.loadAjax();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to);
   next(vm => {
        vm.id = to.query.id;
        vm.loadAjax();
   });
  },
  beforeRouteUpdate(to,from,next){
        this.id = to.query.id;
        this.loadAjax();
        next();
  },
  methods: {
    loadAjax(){  //初始化数据
        
        this.$axios.post('/api/Article/getArticleInfo',{catid:3,id:this.id}).then((res)=>{
            // console.log(res);
            if(res.code==1){
                this.caseinfo = res.data;
                document.title = res.data.title;
            }
            
        }).catch((erro)=>{
            console.log(erro)
        });
    },
    nextPage(){  //下一页
        // this.$router.push({query:merge(this.$route.query,{'id':this.caseinfo.nextData.id})})
        if(this.caseinfo.nextData!=null){
            var _id = this.caseinfo.nextData.id;
            this.$router.push({
                query:merge(this.$route.query,{'id':_id})
            });
            // this.loadAjax();
        }
        
    },
    prevPage(){ //上一页
        // this.$router.push({query:merge(this.$route.query,{'id':this.caseinfo.nextData.id})})
        if(this.caseinfo.prevData!=null || this.id !=this.caseinfo.prevData.id){
            var _id = this.caseinfo.prevData.id;
            this.$router.push({
                query:merge(this.$route.query,{'id':_id})
            });
            // this.loadAjax();
        }
        
    }
  }
}
</script>
